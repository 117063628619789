/*
    CSS for the panel which controls starting/aborting round mode
*/

.roundModePanel {
    display:flex;
}

.MuiButtonGroup-grouped {
    border-width: 1px !important;
}

.MuiButtonGroup-grouped:hover {
    border-width: 1px !important;
    box-shadow: var(--control-shadow) var(--ui-color);
    color: var(--ui-color);
    transition-duration: 0.2s;
}
.roundModePanel-startButton-button, .roundModePanel-startButton-button .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent; /* make it transparent */
    border-radius: 50%;
    border-width: 0 !important;
    height: 8vh;
    width: 8vh;
    margin: auto;
}

.normal-mode .roundModePanel-startButton-button {
    border: 2px solid var(--ui-control-color);
    color: var(--ui-control-color);
    transition-duration: 0.2s;
}

.normal-mode .roundModePanel-startButton-button:hover {
    color: var(--ui-color);
    transition-duration: 0.2s;
}

/* If we're in round mode then displaying the time selector makes no sense. */
.round-mode .roundLengthSelectPanel {
    display: none;
}
.round-mode .roundModePanel-startButton-button {
    border: 2px solid var(--ui-alert-color);
    color: var(--ui-alert-color);
    padding-bottom: 1.5vh;
    margin: auto;
}

.round-mode .roundModePanel-startButton-button:hover {
    background-color: var(--ui-alert-color);
    color: var(--ui-control-color);
}

.MuiButton-containedPrimary {
    background-color: rgba(var(--ui-control-color-rgb), 0.9) !important;
  /*  border: 1px solid var(--ui-color) !important;*/
    color: black !important;
}