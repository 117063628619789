.normalModePanel {
  width: 10vh;
  height: 10vh;
  left: 50%;
  bottom: -1vh;
  position: fixed;
  z-index: 2;
  border-radius: 50%;
  transform: translate(-50%, 0);
  transition-duration: 0.2s;
  cursor: pointer;
}

.userIsIdle .normalModePanel {
  bottom: -8vh;
  transition-duration: 0.5s;
}

.normalModePanel:hover {
  box-shadow: var(--control-shadow) var(--ui-color);
  transition-duration: 0.2s;
}

.normalGetIn {
  background: #00000052 url("/public/resources/images/go-in.svg") no-repeat center;
  transition-duration: 0.5s;
}

.normalGetIn:hover {
  background: url("/public/resources/images/go-in.svg") no-repeat center;
  transition-duration: 0.2s;
}

.normalQueueUp {
  background: #00000052 url("/public/resources/images/hand-up.svg") no-repeat center;
}

.normalLeaveQueue {
  background: #00000052 url("/public/resources/images/hand-down.svg") no-repeat center;
}

.normalStopSpeaking {
  background: #00000052 url("/public/resources/images/go-out.svg") no-repeat center;
}

.normalStopSpeakingSomeoneWaiting {
  background: #00000052 url("/public/resources/images/go-out.svg") no-repeat center;
  box-shadow: var(--control-shadow) var(--ui-highlight-color);
  transition-duration: 0.2s;
}
