/*
    CSS for CircleMeeting component
 */
.circleMeeting {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.circleMeeting .pageLogo {
    top: 1vh;
    left: 1vh;
    position: fixed;
}

.circleMeeting .pageLogo img {
    width: 40%;
    opacity: 0.3;
}
