#buttonLeaveSession {
  right: 10px;
  top: 60px;
  width: 290px;
}

/* the whole bottom footer control area... */
#controlBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7vh;
  background-color: rgb(209 204 204 / 31%);
  transition-duration: 0.2s;
}

.userIsIdle #controlBar {
  bottom: -7vh;
  transition-duration: 1s;
}
.userIsPresent #controlBar {
  bottom: 0;
  transition-duration: 0.5s;
}

#roomName {
  display: inline-block;
  color: var(--ui-control-color);
  opacity: 0.7;
  line-height: 1;
  font-size: 5vh;
  font-weight: 500;
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

#controlLeft {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 7vh;
  display: flex;
  justify-content: left;
  text-overflow: nowrap;
  width: 50%;
}
#controlRight {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 7vh;
  display: flex;
  justify-content: flex-end;
  text-overflow: nowrap;
}

.likeEmoji {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 35px;
  height: 7vh;
  display: flex;
  justify-content: right;
  text-overflow: nowrap;
  width: 10vh;
  background: transparent;
  background-color: transparent;
  border: none;
}

.changeToRound {
  background: rgba(0, 0, 0, 0) url("/public/resources/images/round.svg")
    no-repeat center/80%;
  width: 4vh;
  height: 4vh;
  display: flex;
  padding-left: 2vh;
  z-index: 2;
  border-radius: 50%;
}

.changeToNormal {
  background: rgba(1, 1, 1, 0) url("/public/resources/images/close.svg")
    no-repeat center/80%;
  width: 4vh;
  display: flex;
  height: 4vh;
  opacity: 0.3;
  z-index: 3;
  border: 0.5vh solid red;
  border-radius: 50%;
}

.changeToNormal:hover {
  opacity: 1;
}

#controlBar .MuiIconButton-root {
  color: var(--ui-control-color);  
  display: flex;
  align-items: center;
  height: 7vh;
  width: 7vh;
}
.MuiIconButton-root:hover {
  background-color: rgba(var(--ui-control-color-rgb), 0.3) !important;
  transition-duration: 0.2s;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  bottom: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--ui-control-color);
  border-radius: 2px;
  height: 15%;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--ui-control-color);
}