/*
    CSS for PersonStreamCircle component and the videos it embeds.
 */
/* .personStreamCircle {
} */
.personStreamCircle video {
  border-radius: 50%;
  overflow: hidden;
  height: 100%;
  width: 100%;
  object-fit: cover;
  float: left;
}

.personStreamCircle .streamcomponent {
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0px 20px 6px rgba(var(--ui-control-color-rgb), 0.45);
}

/* Dealing with Mute */
.personStreamCircle .microphoneMuteIndicator {
  color: rgba(var(--ui-control-color-rgb), 0.8);
  font-weight: bold;
  position: absolute;
  right: 20%;
  bottom: 5%;
  width: 4vh !important;
  height: 4vh !important;
  z-index: 1;
  transition-duration: 0.4s;
}

.personStreamCircle .hearthIcon {
  position: absolute;
  left: 20%;
  bottom: 5%;
  width: 8vh !important;
  height: 8vh !important;
  z-index: 1;
  transition-duration: 0.4s;
}

.personStreamCircle .personName {
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
  margin: auto;
  z-index: 1;
  color: var(--ui-control-color);
  font-weight: 500;
  font-size: 2em;
  opacity: 0.7;
}
.circleOccupied .personStreamCircle .mutedOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0.0, 0.0, 0.0, 0.4);
  border-radius: 50%;
  z-index: 5;
}

.personStreamCircle.micOff .microphoneMuteIndicator {
  opacity: 0.85;
  transition-duration: 0.4s;
}
.userIsIdle .personStreamCircle.micOff .microphoneMuteIndicator {
  opacity: 0;
  transition-duration: 0.4s;
}

/* If I'm in the waiting queue */
.nextInQueue {
  box-shadow: var(--control-shadow) var(--ui-highlight-color) !important;
}

.personStreamCircle:hover + .kick-out{ 
  display: block
}