html {
  position: relative;

  /* NOTE REACT does not like position:fixed elements, breaks the clickablity of things somehow
  so we have to use 100% sized elements all the way down */
  min-height: 100%;
  height: 100%;
  width: 100%;
}

body {
  background-color: rgba(22, 22, 22, 1);
  overflow-y: hidden;
  overflow-x: hidden;
  margin:0;

  /* NOTE REACT does not like position:fixed elements, breaks the clickablity of things somehow
  so we have to use 100% sized elements all the way down */
  height: 100%;
  width: 100%;
}

* {
  font-family: "Comfortaa", cursive;
  font-weight: 300;
}

/* .floatCenter {
    width: 100vw;
    height: 100vh;
} */

button:focus {
  outline: none;
  box-shadow: none;
}
