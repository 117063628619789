:root {
  --ui-highlight-color: #ff8500;
  --ui-highlight-color-rgb: 255, 133, 0;

  --ui-color: lightgreen;
  --ui-color-rgb: 144, 238, 144; /* RGB for above so can use with opacity when needed*/

  --ui-alert-color: indianred;

  --ui-control-color: rgb(230, 230, 230); /* used for UI controls */
  --ui-control-color-rgb: 230, 230, 230;
  --ui-submit-button-color: #495057;
  --ui-input-bg-color: #e9ecef;
  --ui-input-border-color: #ced4da;
  --ui-color-white: #ffffff;

  --control-shadow: 0 0 5px 5px; /* This is the standard shadow spread we put on controls */
}
