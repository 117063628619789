/* ---------------------------------------------------------------- */
/* -------------------    BURGER MENU RELATED CSS ----------------- */
/* ---------------------------------------------------------------- */

.isHost-false .hostSettings {
  display: none !important;
}
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: var(--ui-control-color);
  transition: color 0.2s;
}

/* General sidebar styles */
.bm-menu {
  position: fixed;
  height: 100%;
  padding: 1vw 1vh;
  font-size: 1.15em;

  /* Don't allow menu items to leak-out of the menu! */
  overflow: hidden;
  width: 20%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item {
  max-width: 10em;
  margin: auto;
}
.bm-menu .menuItem {
  border: 1px dashed rgba(var(--ui-control-color-rgb), 0.5);
  border-radius: 5px;
  padding: 0.5em;
  margin: 1vh 0;
}

.pageLogo {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

/* Make the icon size larger */
.menuItem .MuiButton-iconSizeLarge > *:first-child {
  font-size: 1.8em;
}

.bm-menu .MuiButton-root {
  transition: 0.2s;
  text-transform: none;
  width: 100%;
}
.bm-menu .MuiButton-outlined {
  color: var(--ui-control-color);
  border: 1px solid var(--ui-control-color);
}

.bm-menu .MuiButton-outlined:hover {
  color: var(--ui-color);
  border: 1px solid var(--ui-color);
  box-shadow: var(--control-shadow) var(--ui-color);
}

.forceMuteAllButton {
  color: var(--ui-alert-color) !important;
  border-color: var(--ui-alert-color) !important;
  transition: 0.2s;
}

.forceMuteAllButton:hover {
  color: var(--ui-control-color) !important;
  border-color: var(--ui-control-color) !important;
  background-color: var(--ui-alert-color) !important;
  box-shadow: none !important;
  transition: 0.2s;
}

.menuItemTitle {
  width: 100%;
  text-align: center;
  color: var(--ui-control-color);
}
.menuItemTitle .MuiSvgIcon-root {
  font-size: 1.8em;
}
a.settingsButton {
  appearance: button;
  text-decoration: none;
  color: white;
  border: 1px solid white !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  user-select: none;
  vertical-align: middle;
  min-width: 64px;
  width: 100%;
}
.text {
  padding-left: 0.5rem;
}

.closeRoomButton:hover {
  color: var(--ui-alert-color) !important;
  border-color: var(--ui-alert-color) !important;
  box-shadow: var(--control-shadow) var(--ui-alert-color) !important;
}