.circleSpace {
  width: 100%;
  height: 100%;
  /* NOTE REACT does not like position:fixed elements, breaks the clickablity of things somehow
    so we have to use 100% sized elements all the way down*/
}
.circleSpace #bm-page-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;

  /* To make the room-background setting work properly */
  width: 100%;
  height: 100%;
  position: absolute;
}

/* ------------- DEFAULT Room Styles  ---------- */
/* just keep this for a custom room name */
#bm-page-wrap {
  background-image: url('/public/resources/backgrounds/ash-1866620_1920.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.mainEmpty .emptyCircle {
  background: url('/public/resources/images/center.gif');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* When the user is idle, hide the cursor... Let them focus on the conversation */
.userIsIdle {
  cursor: none;
}
