.personBox {
  left: 50%;
  bottom: 0;
  z-index: 2;
  transform: translate(-50%, 0);
  border-radius: 50%;
  transition-duration: 0.2s;
  background: rgba(0, 0, 0, 0.5);
}

.personEmptyBox {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  transition-duration: 0.2s;
}

.isSpeaking {
  box-shadow: var(--control-shadow) var(--ui-color);
  transition-duration: 0.2s;
  border: 2px solid var(--ui-color);
}
/* .personBox:hover {
  box-shadow: var(--control-shadow) var(--ui-color);
  transition-duration: 0.2s;
  border: 2px solid var(--ui-color);
} */
.personBox * {
  font-size: 4vh !important;
}

.personBox .MuiCircularProgress-colorPrimary {
  color: rgba(var(--ui-control-color-rgb), 0.7) !important;
}

.kick-out {
  display: none;
  position: absolute;
  bottom: 50%;
  float: right;
  right: 50%;
  width: 6vh;
  height: 6vh;
  box-sizing: border-box;
  z-index: 2;
  border-radius: 50%;
  transform: translate(50%, 50%);
  cursor: pointer;
  background: #00000052 url("/public/resources/images/crosslinear_106242.svg") no-repeat center;
}

.kick-out:hover {
  display: block;
}