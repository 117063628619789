/*
    CSS shared between components...
 */
.emptyCircle {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.absolutePositionCircle {
    position: absolute;
    object-fit: cover;
}