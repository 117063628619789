.versionNo {
  color: var(--ui-control-color);
}
.bm-menu .versionNo {
  font-size: 1em;
  text-align: center;
}
.circlesDialog .versionNo {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 1em;
  font-size: 1.5em;
}
