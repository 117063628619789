.roundModeControl-speaking{
    left: 50%;
    bottom: 0;
    z-index: 2;
    transform: translate(-50%, 0);
    border-radius: 50%;
    transition-duration: 0.2s;
    box-shadow: var(--control-shadow) var(--ui-control-color);
    border: 2px solid var(--ui-control-color);
}

.roundModeControl-speaking:hover {
    box-shadow: var(--control-shadow) var(--ui-color);
    transition-duration: 0.2s;
    border: 2px solid var(--ui-color);
}
.roundModeControl-speaking *{
    font-size: 4vh!important;
    cursor: pointer;
}

.roundModeControl-speaking .MuiCircularProgress-colorPrimary {
    color: rgba(var(--ui-control-color-rgb), 0.7) !important;
}

.roundModeControl-inQueue{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vh;
    width: 10vh;
    height: 10vh;
    left: 50%;
    bottom: 0;
    position: fixed;
    z-index: 2;
    color: var(--ui-highlight-color);
    box-shadow: var(--control-shadow) var(--ui-highlight-color);
    border-radius: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(var(--ui-highlight-color-rgb), 0.3);

    cursor: pointer;
    font-weight: bold;
}

.roundModeControl-haveSpoken{
    width: 10vh;
    height: 10vh;
    left: 50%;
    bottom: 0;
    position: fixed;
    z-index: 2;
    border: 1vh solid rgba(0,0,0,0.7);
    border-radius: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(70,70,70,0.6);
}