.circlesDialog {
  color: black;
  background: url("/public/resources/images/circle.svg");
  background-size: 30em 30em;
  background-repeat: no-repeat;
  background-position: center center;
}

.alertContainer {
  padding: 0 1rem;
}

.floatCenter {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.circlesDialogRowContainer {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  height: 100% !important;
  justify-content: center !important;
}
/* Fit the text in the circle - at least width wise... */
.circlesDialogChildren {
  max-width: 25em;
  width: 25em;
  text-align: center;
  align-self: center;
}
.alertBoxLogo {
  position: absolute;
  width: 5em;
}


.errorDialog {
  background-color: var(--ui-alert-color) !important;
  color: var(--ui-alert-color) !important;
}

.errorDialog p {
  font-size: 2em;
  font-weight: 600;
}

/* 
For further knowledge of mq please check below links 
https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints/
https://www.toptal.com/responsive-web/introduction-to-responsive-web-design-pseudo-elements-media-queries 
*/
@media screen and (max-width: 449px) {
  .circlesDialogChildren {
    max-width: 16em;
    width: 16em;
  }

  .circlesDialog {
    background-size: 21em 21em;
  }
}
